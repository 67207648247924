
import React from 'react';
import $ from 'jquery';

import './company.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, updateAdminPerm, updateMyAlarm, isEmptyObj } from '../../libs/utils';
import {requestPost, requestFile, fileDownload} from '../../libs/request';

class DetailCompany extends React.Component {
    fileref = null;
    constructor() {
        super();
        this.state = {
            dummy: 0,
            partnerId:    0,
            partner_id:   0,        // 파트너사 신청 상세에서 '신규 교육사 생성'으로 넘어왔을 때
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            hiData:  {},
            
            totalCnt:     0,
            currentPage:  1,
            eduList:      [],

            totalCnt2:     0,
            currentPage2:  1,
            oneTalkList:   [],
            // 
            user_name:  '', 
            homepage:   '', 
            intro:      '', 
            thumb:      '', 
            expo:       'E', 
            buss_num:   '', 
            company:    '', 
            owner:      '', 
            service:    '', 
            buss_file:  '', 
            communit_file:  '', 
            brand_file: '', 
            buss_path:  '', 
            communit_path:  '', 
            brand_path: '', 
            manager_name: '', 
            phone:      '', 
            m_email:    '', 
            status:     3, 
            alarm_partner_talk: 'Y',
            alarm_review: 'Y',
            alarm_premium_review: 'Y',
            alarm_unmatched_review: 'Y',
            alarm_qna: 'Y',
        };
    }

    componentDidMount() {

        const partnerId = localStorage.getItem('user_id');
        // 
        updateAdminPerm();
        updateMyAlarm();
        // 
        this.getData(partnerId);
        // 
    }

    getData = (partnerId) => {
        window.scrollTo(0, 0);
        this.setState({partnerId});
    
        if(partnerId > 0) {
            requestPost('getEduCompanyDetail', {partnerId}, (res) => {
                if(Boolean(res)) {
                    const { id, email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                        brand_path, manager_name, phone, m_email, status, accep_time, reg_time, alarm_review, alarm_premium_review, alarm_unmatched_review, alarm_qna, alarm_partner_talk } = res;
        
                    this.setState({ id, email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                        brand_path, manager_name, phone, m_email, status, accep_time, reg_time, alarm_review, alarm_premium_review, alarm_unmatched_review, alarm_qna, alarm_partner_talk });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
        else {
            this.gotoPage(-1);
        }
    }
    
    onConfirm = () => {
        const {modalIdx} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
        if(modalIdx == 11) {  // 수정 사항을 승인
            this.update();
        }
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    openReqUrl = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }

    updateAlarmSettings = () => {
        const { alarm_review, alarm_premium_review, alarm_qna, alarm_partner_talk, alarm_unmatched_review, id} = this.state;

        const params = {
        dv: 'P',
        alarm_review, alarm_premium_review, alarm_qna, alarm_partner_talk, alarm_unmatched_review, id
        }

        requestPost('updateAlarmSettings', params, (res) => {
        if (Boolean(res)) {
            toastMsg('알람설정이 저장되었습니다.', 'success');
        }
        })
    }

    render() {
        const { partnerId, hiData, totalCnt, currentPage, eduList, totalCnt2, currentPage2, oneTalkList, confirmTitle, confirmTxt, modalIdx, alarm_review, alarm_premium_review, alarm_unmatched_review, alarm_qna, alarm_partner_talk } = this.state;
        const { email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, brand_path, manager_name, phone, m_email, status, accep_time, reg_time } = this.state;
        
        return (
            <Leftmenu>
                <div className='page_contents detailcompany mb-5 pb-5'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>회사 정보</div>
                            <div className='fralign_center' style={{gap:20}}>
                                <div className='btn_normal' onClick={() => this.gotoPage('/company/editcompany')}>정보 수정</div>
                            </div>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                            </div>
                            <div className='fcjust_center' style={{ width: 200 }}>
                                {Boolean(thumb) && <img src={consts.resourcUrl + thumb} className='prod_img_bg' />}
                            </div>
                            <div className='card_row mt-3' style={{ alignItems: 'stretch' }}>
                                <div style={{ flex: 1 }}>
                                    <div className='card_row b-none'>
                                        <label className='field_label'>회사명</label>
                                        <div className='card_row_val'>{user_name}</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>회사 홈페이지</label>
                                        <div className='card_row_val'>{homepage}</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>회사 소개</label>
                                        <div className='card_row_val pre-wrap'>{intro ? intro : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card_body' >
                            <div className='card_row border-1' style={{ gap: 20, alignItems: 'flex-start' }}>
                                <div className='flex-1' style={{ padding: 10 }}>
                                    <div className='frjustify_between w-100 p-3'>
                                        <div className='font_D700_16'>회사 정보</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>사업자등록번호</label>
                                        <div className='card_row_val'>{buss_num}</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>회사(법인)명</label>
                                        <div className='card_row_val'>{company}</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>대표자명</label>
                                        <div className='card_row_val'>{owner}</div>
                                    </div>
                                </div>
                                <div className='flex-1' style={{ padding: 10 }}>
                                    <div className='frjustify_between w-100 p-3'>
                                        <div className='font_D700_16 v-hidden'>회사 정보</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>사업자등록증</label>
                                        <div className='card_row_val'>
                                            {buss_file?.indexOf('.pdf') > 0 ? <a className='underline' href={consts.resourcUrl + buss_path} target='_blank' rel="noreferrer">{buss_file}</a> : ''}
                                            {(Boolean(buss_path) && buss_path.indexOf('.pdf') == -1) && <img src={consts.resourcUrl + buss_path} style={{height: 62}} className='brand_img' />}
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>통신판매업증</label>
                                        <div className='card_row_val'>
                                            {communit_file?.indexOf('.pdf') > 0 ? <a className='underline' href={consts.resourcUrl + communit_path} target='_blank' rel="noreferrer">{communit_file}</a> : ''}
                                            {(Boolean(communit_path) && communit_path.indexOf('.pdf') == -1) && <img src={consts.resourcUrl + communit_path} style={{height: 62}} className='brand_img' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card_row mt-4' style={{ gap: 20, alignItems: 'flex-start', border: 'none' }}>
                                <div className='flex-1 border-1' style={{ padding: 10 }}>
                                    <div className='frjustify_between w-100 p-3'>
                                        <div className='font_D700_16'>담당자 정보</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>운영 담당자명</label>
                                        <div className='card_row_val'>{manager_name}</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>운영 담당자 휴대전화번호</label>
                                        <div className='card_row_val'>{phone}</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>운영 담당자 이메일</label>
                                        <div className='card_row_val'>{m_email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>알림 설정</div>
                        </div>
                        <div className='card_row'>
            <label className='field_label'>새로운 수강후기</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_review: 'Y' }) }><input type="radio" name="alarm_review" checked={alarm_review == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_review: 'N' }) }><input type="radio" name="alarm_review" checked={alarm_review == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>새로운 프리미엄 수강후기</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_premium_review: 'Y' }) }><input type="radio" name="alarm_premium_review" checked={alarm_premium_review == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_premium_review: 'N' }) }><input type="radio" name="alarm_premium_review" checked={alarm_premium_review == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>매칭 필요한 수강후기</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_unmatched_review: 'Y' }) }><input type="radio" name="alarm_unmatched_review" checked={alarm_unmatched_review == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_unmatched_review: 'N' }) }><input type="radio" name="alarm_unmatched_review" checked={alarm_unmatched_review == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>새로운 교육상품 문의</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_qna: 'Y' }) }><input type="radio" name="alarm_qna" checked={alarm_qna == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_qna: 'N' }) }><input type="radio" name="alarm_qna" checked={alarm_qna == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>교육 기관 한줄톡</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_partner_talk: 'Y' }) }><input type="radio" name="alarm_partner_talk" checked={alarm_partner_talk == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_partner_talk: 'N' }) }><input type="radio" name="alarm_partner_talk" checked={alarm_partner_talk == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_head'>
            <div className='fralign_center' style={{ gap: 10 }}>
              <div className='btn_normal' onClick={this.updateAlarmSettings}>알림상태저장</div>
            </div>
          </div>
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailCompany);