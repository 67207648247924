import './company.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';

import { toastMsg, formatDate } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailLinetok extends React.Component {
    
    constructor() {
        super();
        this.state = {
            user_id:    '', 
            partner_id: '', 
            contents:   '', 
            expo:       '', 
            reg_time:   '', 
            user_name:  '', 
            nickname:   '',
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        let oneTalkId = searchParams.get("id");
        this.getData(oneTalkId);
    }

    getData = (oneTalkId) => {
        window.scrollTo(0, 0);
        this.setState({oneTalkId});
    
        requestPost('getOneTalkDetail', {oneTalkId}, (res) => {
            if(Boolean(res)) {
                const { user_id, partner_id, contents, expo, reg_time, user_name, nickname } = res;
        
                this.setState({ user_id, partner_id, contents, expo, reg_time, user_name, nickname });
            }
            else {
                alert('삭제된 내역입니다.');
                this.gotoPage(-1);
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { oneTalkId, contents, expo, reg_time, nickname } = this.state;
        
        return (
            <Leftmenu>
                <div className='page_contents detaillinetok'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>한줄톡 상세</div>
                        </div>
                        <div className='card_body' style={{gap:10}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>한줄톡ID</th>
                                        <td>{oneTalkId}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자</th>
                                        <td>{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td className='pre-wrap'>{contents}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Leftmenu>
        );
    }
}

export default withRouter(DetailLinetok);