
import React from 'react';
import parse from 'html-react-parser';

import './education.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import ConformModal from '../../components/modal/ConformModal';
import QueryPreView from '../../components/modal/query_prevView';

import {requestPost, requestFile, nonAuthedReqPost} from '../../libs/request';
import { formatDate2, getEduDays, toastMsg, getCurrentTime, formatTime, isEmptyObj, formatDate, formatNum, renderMethod } from '../../libs/utils';
import consts from '../../libs/consts';

class DetailLeasson extends React.Component {
  editorref = null;
  fileref = null;
  constructor() {
    super();
    this.state = {
      productId: '',
      support_price: 0,
      modalIdx:     0,
      set_status:   5,
      areaList:     [],
      areaSubList:  [],
      confirmTitle: '',
      confirmTxt:   '',

      // detail
      partner_id:  '', 
      user_name:  '', 
      title: '', 
      thumb: '', 
      st_date: new Date(), 
      ed_date: new Date(), 
      expire_date: new Date(), 
      expire_time: new Date(), 
      price_type: 1, 
      price:      0, 
      cond_txt: '', 
      support_price: -1, 
      method: 1, 
      area1_name: '', 
      area2_name: '', 
      schedule: '', 
      req_url: '', 
      comment: '', 
      note: '', 
      jobtype: '', 
      tech_stack: '', 
      difficulty: '', 
      interest: '', 
      customer_type: '', 
      curriclum: '', 
      curriclum_time: '', 
      lesson_review: '', 
      faq: '', 
      intro: '', 
      return_txt: '', 
      updated: '', 
      status: 5, 
      reg_time: '', 
    };
  }

  componentDidMount() {

    const { searchParams } = this.props.router;
    let productId = searchParams.get("id");
    productId = Boolean(productId) ? productId : '';
    // 
    this.getData(productId);
    
  }

  getData = (productId) => {
    window.scrollTo(0, 0);
    this.setState({productId});
    
    const initDatas = (res) => {
      
      const { partner_id, user_name, title, thumb, st_date, ed_date, expire_time, no_limit_time1, no_limit_time2, price_type, price, cond_txt, support_price, method, area1_name, area2_name, schedule, req_url, comment, note, jobtype, tech_stack, difficulty, interest, customer_type, curriclum, curriclum_time, lesson_review, faq, intro, return_txt, updated, status, reg_time } = res;

      this.setState({ partner_id, user_name, title, thumb, price_type, no_limit_time1, no_limit_time2, price, cond_txt, support_price, method, area1_name, area2_name, schedule, req_url, jobtype, tech_stack, difficulty, interest, customer_type, intro, updated, status, reg_time,
        set_status: status, curriclum_time,
        return_txt: Boolean(return_txt) ? return_txt : '', 
        comment: Boolean(comment) ? comment : '', 
        note: Boolean(note) ? note : '', 
        curriclum: Boolean(curriclum) ? curriclum : '', 
        lesson_review: Boolean(lesson_review) ? lesson_review : '', 
        faq: Boolean(faq) ? faq : '', 
        st_date: new Date(st_date), 
        ed_date: new Date(ed_date), 
        expire_date: new Date(expire_time), 
        expire_time: new Date(expire_time)
      });
    }
    if(productId > 0) {
      // 임시 저장된 데이터가 있는가?
      requestPost('getProductDetailTmp', {productId}, (res) => {
        if(Boolean(res)) {
          initDatas(res);
        }
        else {
          // get org data
          requestPost('getProductDetail', {productId, type: 'partner'}, (org_data) => {
            if(Boolean(org_data)) {
              initDatas(org_data);
            }
            else {
              alert('삭제된 내역입니다.');
              this.gotoPage(-1);
            }
          });
        }
      });
    }
    else {  // register
      this.gotoPage(-1);
    }
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }
  openReqUrl = (url) => {
    window.open(url, '_blank', 'noreferrer');
  }
  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }
  renderKeyword = ({data}) => {
    if(!data) return '';
    data = data.split(',');
    
    return (
      data.map((item, index) => <div key={index} className='btn_option c-default'>{item}</div>)
    )
  }

  // 상품 삭제
  deleteProduct = () => {
    const { productId, status } = this.state;

    requestPost('deleteProduct', {productId, type: status == 5 ? 'delete' : '' }, (res) => {
      if(Boolean(res)) {
        this.props.router.navigate('/education/leasson');
      }
    });
  }
  // 
  onConfirm = () => {
    const {modalIdx} = this.state;
    this.setState({modalIdx: 0, confirmTxt: ''});

    if(modalIdx == 10) {  // 상품 삭제
      this.deleteProduct();
    }
  }
  render() {
    const { productId, expire_date, expire_time, set_status, modalIdx, areaList, areaSubList, confirmTitle, confirmTxt, hiData, checkEdit } = this.state;
    const { partner_id, user_name, title, thumb, st_date, ed_date, price_type, price, cond_txt, support_price, method, area1_name, area2_name, schedule, req_url, comment, 
      note, jobtype, tech_stack, difficulty, interest, customer_type, curriclum, curriclum_time, lesson_review, faq, intro, no_limit_time1, no_limit_time2 } = this.state;

    const cur_time = getCurrentTime();
    const exp_time = formatDate2(expire_date) + ' ' + formatTime(expire_time) + ':00';  // 모집마감일시
    // 
    let curriclumList = [];
    let faqList       = [];
    let reviewList    = [];
    try {
      curriclumList = Boolean(curriclum) ? JSON.parse(curriclum) : [];
      faqList       = Boolean(faq) ? JSON.parse(faq) : [];
      reviewList    = Boolean(lesson_review) ? JSON.parse(lesson_review) : [];
    } catch (error) {
      console.error(error)
    }

    return (
      <Leftmenu>
        <div className='page_contents detailleasson mb-5'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>교육 상품 상세</div>
              <div className='fralign_center' style={{ gap: 10 }}>
                <div className='btn_link' onClick={() => window.open(consts.domain + 'edudetail?type=1&id=' + productId, "_blank")}>미리보기</div>
                <div className='btn_normal cancel' onClick={() => this.gotoPage('/education/leasson/new?id=' + productId)}>정보 수정</div>
                <div className='btn_normal' style={{ width: 120 }} onClick={() => this.setState({confirmTitle: "삭제하기", confirmTxt: "해당 교육을 삭제하시겠습니까?", modalIdx: 10})}>교육 삭제</div>
              </div>
            </div>
            <div className='card_body'>
              <div className='frjustify_between splite_head' style={{ width: '100%' }}>
                <div className='font_D700_16 my-3'>기본 정보</div>
              </div>
              <div className='card_row' style={{ alignItems: 'stretch' }}>
                <div style={{ flex: 1 }}>
                  <div className='card_row' style={{ borderTop: 0 }}>
                    <label className='field_label'>상품ID</label>
                    <div className='card_row_val'>{productId}</div>
                  </div>
                  <div className='card_row'>
                    <label className='field_label'>노출상태</label>
                    <div className='card_row_val'>{set_status == 2 ? '노출' : '비노출'}</div>
                  </div>
                  <div className='card_row'>
                    <label className='field_label'>교육상품명</label>
                    <div className='card_row_val'>{title}</div>
                  </div>
                  <div className='card_row'>
                    <label className='field_label'>교육시작/종료일</label>
                    <div className='card_row_val'>
                      {formatDate2(st_date)} ~ {formatDate2(ed_date)}
                      <span>{getEduDays(st_date, ed_date)}</span>
                      <span>{no_limit_time1 == 1 ? '제한 없음' : ''}</span>
                    </div>
                  </div>
                  <div className='card_row'>
                    <label className='field_label'>모집마감일시</label>
                    <div className='card_row_val'>
                      {formatDate(expire_time)}
                      {no_limit_time2 == 1 ? <span>상시</span> : <span>{cur_time < exp_time ? '모집중' : '모집종료'}</span>}
                    </div>
                  </div>
                </div>
                <div className='fcjust_center' style={{ width: 300, alignItems: 'center', borderLeft: '1px solid #EEEEEE', padding: 10, gap: 6, boxSizing: 'border-box' }}>
                  <label className='field_label w-100'>상품이미지</label>
                  <input type="file" ref={(val) => { this.fileref = val }} id="file" style={{ display: 'none' }} onChange={this.handleFileChange} />
                  {Boolean(thumb) ? <img src={consts.resourcUrl + thumb} className='prod_img_bg' /> : <div className='prod_img_bg' style={{height: '100%'}} />}
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>가격유형</label>
                <div className='card_row_val'>
                  {price_type == 1 ? '무료' : (price_type == 2 ? '조건부 무료' : '유료')}
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>가격</label>
                <div className='card_row_val'>
                  {price_type == 3 ? formatNum(price) : '-'}
                </div>
              </div>
              {price_type == 2 &&
              <div className='card_row'>
                <label className='field_label'>조건입력</label>
                <div className='card_row_val'>
                  {cond_txt}
                </div>
              </div>}
              <div className='card_row'>
                <label className='field_label'>지원료</label>
                <div className='card_row_val'>
                  {support_price == -1 ? '없음' : formatNum(support_price)}
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>학습방식</label>
                <div className='card_row_val'>
                  {renderMethod(method)}
                </div>
              </div>
              {(method == 2 || method == 4) &&
              <div className='card_row'>
                <label className='field_label'>지역</label>
                <div className='card_row_val'>
                  {area1_name} {area2_name}
                </div>
              </div>}
              <div className='card_row'>
                <label className='field_label'>수업일정</label>
                <div className='card_row_val'>
                  {schedule}
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>교육신청URL</label>
                <div className='card_row_val'>
                  <div className='flex-1'>{req_url}</div>
                  <div className='btn_link ml-2' onClick={() => this.openReqUrl(req_url)} style={{width: 130}}>새 창으로 열기</div>
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>에디터스코멘트</label>
                <div className='card_row_val pre-wrap'>
                  {comment}
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>유의사항</label>
                <div className='card_row_val pre-wrap'>
                  {note}
                </div>
              </div>
              <div className='frjustify_between splite_block' style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                <div className='font_D700_16'>상품 키워드 입력</div>
              </div>
              <div className='card_row'>
                <label className='field_label'>직무</label>
                <div className='card_row_val flex-wrap'>
                  <this.renderKeyword data={jobtype} />
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>기술스택</label>
                <div className='card_row_val flex-wrap'>
                  <this.renderKeyword data={tech_stack} />
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>난이도</label>
                <div className='card_row_val flex-wrap'>
                  <this.renderKeyword data={difficulty} />
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>관심정보</label>
                <div className='card_row_val flex-wrap'>
                  <this.renderKeyword data={interest} />
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>고객유형</label>
                <div className='card_row_val flex-wrap'>
                  <this.renderKeyword data={customer_type} />
                </div>
              </div>
              <div className='frjustify_between splite_block w-100' style={{ paddingTop: 20, paddingBottom: 10 }}>
                <div className='font_D700_16'>커리큘럼</div>
                <div className='btn_link' onClick={() => this.setState({modalIdx: 7})}>미리보기</div>
              </div>
              <div className='card_row'>
                <label className='field_label'>전체시간</label>
                <div className='card_row_val pre-wrap' style={{justifyContent: 'flex-end'}}>
                  {curriclum_time}시간
                </div>
              </div>
              <div className='fcjust_center pb-3 w-100' style={{ gap: 10 }}>
                <div className='query_block p-0 px-3'>
                  <div className='justify-content-between w-100'>
                    <span className='field_label'>대주제명</span>
                    <span className='field_label'>시간 또는 주차 설명 표시</span>
                  </div>
                </div>
                {
                  curriclumList.map((item, index) => {
                    return (
                        <div key={index}>
                          <div className='query_block p-0 px-3'>
                            <div className='justify-content-between w-100'>
                              <span className='field_label'>{item.title}</span>
                              <span className='field_label'>{item.time}</span>
                            </div>
                          </div>
                          {
                            item.data?.map((data, idx) => {
                                return (
                                  <div className='px-3' key={index +'' + idx}>
                                    <div className='justify-content-between pl-3 w-100'>
                                      <span className='field_label'>{data.title}</span>
                                      <span className='field_label'>{data.time}</span>
                                    </div>
                                  </div>
                                )
                              }
                            )
                          }
                        </div>
                    )
                })
                }
              </div>
              <div className='frjustify_between splite_block w-100' style={{ paddingTop: 20, paddingBottom: 10 }}>
                <div className='font_D700_16'>수강후기</div>
              </div>
              {
                reviewList.map((item, index) =>
                <div className={`card_row`} key={index}>
                  <div className='card_row_val'>
                    <div>
                      <div className='mb-1'>{item.title}</div>
                      <div className='gray-text fs-12'>{item.link}</div>
                    </div>
                  </div>
                </div>
                )
              }
              <div className='frjustify_between splite_block w-100' style={{ paddingTop: 20, paddingBottom: 10 }}>
                <div className='font_D700_16'>FAQ</div>
              </div>
              <div className='fcjust_center w-100 pb-3' style={{ gap: 10 }}>
                <div className='query_block p-0 px-3'>
                  <div className='justify-content-between w-100'>
                    <span className='field_label'>질문</span>
                  </div>
                </div>
                {
                  faqList.map((item, index) => {
                    return (
                      <div key={index} className='field_label p-0'>
                        <div className='query_block p-3'>
                          {item.question}
                        </div>
                        <div className='p-3 pb-2 ms-3 pre-wrap'>
                          {item.answer}
                        </div>
                      </div>
                    )
                })
                }
              </div>
              <div className='frjustify_between splite_block w-100' style={{ paddingTop: 20, paddingBottom: 10 }}>
                <div className='font_D700_16'>클래스 소개</div>
              </div>
              {Boolean(intro) && <div className='mb-3'>{parse(intro)}</div>}
            </div>
          </div>
        </div>
        {modalIdx == 7 && <QueryPreView closeModal={() => this.setState({modalIdx: 0})} curriclumList={curriclumList} />}

        {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
      </Leftmenu>
    );
  }
}

export default withRouter(DetailLeasson);