import './education.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import EditAnswer from '../../components/modal/edit_answer';

import ConformModal from '../../components/modal/ConformModal';
import { toastMsg, formatDate } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailQueryProd extends React.Component {
    constructor() {
        super();
        this.state = {
            prodReqId:    0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        const prodReqId  = searchParams.get("id");
        // 
        this.getData(prodReqId);
    }

    getData = (prodReqId) => {
        window.scrollTo(0, 0);
        this.setState({prodReqId});
    
        if(prodReqId > 0) {
            requestPost('getProdRequestDetail', {prodReqId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, product_id, contents, expo, answer, answer_time, reg_time, nickname, prod_title } = res;
        
                    this.setState({ user_id, product_id, contents, expo, answer, answer_time, reg_time, nickname, prod_title });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }
    
    updateAnswer = (answer) => {
        const { prodReqId } = this.state;
        this.setState({modalIdx: 0});

        if(!answer) {
            return toastMsg('답변 내용을 작성하세요.');
        }
        const params = {
            prodReqId, answer, type: 'answer'
        }
        requestPost('updateProdRequest', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.setState({answer, answer_time: 'now'});
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }
    onConfirm = () => {
        const {answer} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        this.updateAnswer(answer);
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { prodReqId, confirmTitle, confirmTxt, modalIdx,
            user_id, product_id, contents, expo, answer, answer_time, reg_time, nickname, prod_title } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailqueryprod'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>상품 문의 상세</div>
                            <div className='fralign_center' style={{gap:20}}>
                                {Boolean(answer_time) && <div className='btn_normal' onClick={() => this.setState({modalIdx: 1})}>답변 수정</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{ padding: 10 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>상품문의ID</th>
                                        <td>{prodReqId}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자</th>
                                        <td >{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>상품명</th>
                                        <td className='cursor_pointer' onClick={() => this.gotoPage('/education/leasson/detail?id=' + product_id)}>{prod_title}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td colSpan={3}>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>답변여부</th>
                                        <td colSpan={3}>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>문의 내용</th>
                                        <td className='pre-wrap' colSpan={3}>{contents}</td>
                                    </tr>
                                    {Boolean(answer_time) &&
                                    <tr>
                                        <th>답변 내용</th>
                                        <td className='pre-wrap' colSpan={3}>{answer}</td>
                                    </tr>}
                                </tbody>
                            </table>
                            {Boolean(answer_time) ||
                            <>
                            <div className='fr_c_end w-100' style={{ padding: '20px 0px 10px 0px' }}>
                                <div className='btn_normal' style={{ width: 120 }} onClick={() => this.setState({confirmTitle: "상품 문의 상세", confirmTxt: "상품 문의 답변을 게시하시겠습니까?", modalIdx: 12})}>답변 게시</div>
                            </div>
                            <textarea style={{ width: '100%', height: 200 }} placeholder={"답변 내용을 작성하세요."} value={answer} onChange={({target}) => this.setState({answer: target.value})} />
                            </>}
                        </div>
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
                {modalIdx == 1 && <EditAnswer closeModal={() => this.setState({modalIdx: 0})} title={"답변 수정"} text={answer} selectDatas={this.updateAnswer} />}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailQueryProd);