
import React from 'react';
import $ from 'jquery';

import './company.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, updateAdminPerm, updateMyAlarm, isEmptyObj } from '../../libs/utils';
import {requestPost, requestFile, fileDownload} from '../../libs/request';

class Editcompany extends React.Component {
    fileref = null;
    constructor() {
        super();
        this.state = {
            dummy: 0,
            partnerId:    0,
            partner_id:   0,        // 파트너사 신청 상세에서 '신규 회사 생성'으로 넘어왔을 때
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            hiData:  {},
            
            totalCnt:     0,
            currentPage:  1,
            eduList:      [],

            totalCnt2:     0,
            currentPage2:  1,
            oneTalkList:   [],
            // 
            user_name:  '', 
            homepage:   '', 
            intro:      '', 
            thumb:      '', 
            expo:       'E', 
            buss_num:   '', 
            company:    '', 
            owner:      '', 
            service:    '', 
            buss_file:  '', 
            communit_file:  '', 
            brand_file: '', 
            buss_path:  '', 
            communit_path:  '', 
            brand_path: '', 
            manager_name: '', 
            phone:      '', 
            m_email:    '', 
            status:     3, 
        };
    }

    componentDidMount() {

        const partnerId = localStorage.getItem('user_id');
        // 
        updateAdminPerm();
        updateMyAlarm();
        // 
        this.getData(partnerId);
        // 
    }

    getData = (partnerId) => {
        window.scrollTo(0, 0);
        this.setState({partnerId});
    
        if(partnerId > 0) {
            requestPost('getEduCompanyDetail', {partnerId}, (res) => {
                if(Boolean(res)) {
                    const { email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                        brand_path, manager_name, phone, m_email, status, accep_time, reg_time } = res;
        
                    this.setState({ email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                        brand_path, manager_name, phone, m_email, status, accep_time, reg_time });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
        else {
            this.gotoPage(-1);
        }
    }
    
    handleFileChange = async (event, type, org='') => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      // get dementions
      let img = new Image();
      img.src = URL.createObjectURL(file);
      await img.decode();
      let width = img.width;
      let height = img.height;
      
      if(width != 500 || height != 500) {
        toastMsg('이미지를 500px X 500px로 권장 드립니다.');
      }

      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;
        this.setState({[type]: file_path});
        if(org) {
            this.setState({[org]: originalname});
        }
      });
    }
    handleInputChange = (event) => {
      let target = event.target;
      let value = target.value;
      let name = target.name;
  
      this.setState({[name]: value});
      // 
    }
    onConfirm = () => {
        const {modalIdx} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
        if(modalIdx == 11) {  // 수정 사항을 승인
            this.update();
        }
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    openReqUrl = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }
    // 
    update = () => {
        const { partnerId, 
            user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, brand_path, manager_name, phone, m_email } = this.state;

        let hiData = {};
        if(!user_name) {
            hiData.user_name = 1;
        }
        if(!homepage) {
            hiData.homepage = 1;
        }
        if(!thumb) {
            hiData.thumb = 1;
        }
        if(!buss_num) {
            hiData.buss_num = 1;
        }
        if(!company) {
            hiData.company = 1;
        }
        if(!owner) {
            hiData.owner = 1;
        }
        if(!service) {
            hiData.service = 1;
        }
        if(!buss_file) {
            hiData.buss_file = 1;
        }
        if(!brand_file) {
            hiData.brand_file = 1;
        }
        if(!manager_name) {
            hiData.manager_name = 1;
        }
        if(!phone) {
            hiData.phone = 1;
        }
        if(!m_email) {
            hiData.m_email = 1;
        }
        // 
        this.setState({hiData});
        if(!isEmptyObj(hiData)) {  // 필수 항목 미 입력
            return toastMsg('필수 입력 항목들을 입력하세요.');
        }
        // 
        const params = {
            partnerId, type: 'partner',
            data: {
                user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, brand_path, manager_name, phone, m_email
            }
        }
        requestPost('updateCompanyDetail', params, (res) => {
            if(Boolean(res)) {
                toastMsg('저장되었습니다.', 'success');
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    render() {
        const { partnerId, hiData, totalCnt, currentPage, eduList, totalCnt2, currentPage2, oneTalkList, confirmTitle, confirmTxt, modalIdx } = this.state;
        const { email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, brand_path, manager_name, phone, m_email, status, accep_time, reg_time } = this.state;
        
        return (
            <Leftmenu>
                <div className='page_contents detailcompany mb-5 pb-5'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>회사 정보 수정</div>
                            <div className='fralign_center' style={{gap:20}}>
                                <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>
                                <div className='btn_normal' onClick={this.update}>저장</div>
                            </div>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                                <div className='field_label'><span>*</span> 필수 입력 항목입니다.</div>
                            </div>
                            <div className='card_row' style={{ alignItems: 'stretch' }}>
                                <div style={{ flex: 1 }}>
                                    <div className='card_row'>
                                        <label className='field_label'>회사명<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" className='input_box' placeholder='회사명을 입력하세요' style={{ width: 330 }} value={user_name} onChange={this.handleInputChange} name='user_name'  />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>회사 홈페이지<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" className='input_box w-100' placeholder='홈페이지 URL을 입력하세요' value={homepage} onChange={this.handleInputChange} name='homepage' />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>회사 소개</label>
                                        <div className='card_row_val'>
                                            <textarea style={{ width: '100%', height: 100 }} placeholder={"회사 소개글을 입력하세요"} value={intro ? intro : ''} onChange={this.handleInputChange} name='intro' />
                                        </div>
                                    </div>
                                </div>
                                <div className='fcjust_center' style={{ width: 200, alignItems: 'center', borderLeft: '1px solid #EEEEEE', padding: 10, gap: 6, boxSizing: 'border-box' }}>
                                    <label className={`field_label ${hiData.brand_path ? 'hilight' : ''} w-100`}>회사 이미지(또는 로고)<span>*</span> <br /><span className='gray-text fs-11 mt--2'>png, jpg 파일, 500px X 500px 권장</span></label>
                                    <input type="file" name="thumb" onChange={(e) => this.handleFileChange(e, 'brand_path', 'brand_file')} className="d-none" accept="image/*" />
                                    {Boolean(brand_path) && <img src={consts.resourcUrl + brand_path} className='prod_img_bg' />}
                                    <div className='btn_normal' onClick={() => $('input[name=brand_file]').trigger('click')}>이미지등록</div>
                                </div>
                            </div>
                        </div>
                        <div className='card_body' style={{ border: 'none' }}>
                            <div className='card_row' style={{ gap: 20, alignItems: 'flex-start', border: 'none' }}>
                                <div className='flex-1 border-1' style={{ padding: 10 }}>
                                    <div className='frjustify_between w-100 p-3'>
                                        <div className='font_D700_16'>회사 정보</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>사업자등록번호<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={buss_num} name='buss_num' 
                                            onChange={(e) => {
                                                const check = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
                                                if (e.nativeEvent.data && check.test(e.nativeEvent.data)) {
                                                    return null
                                                }
                                                this.setState({buss_num: e.target.value.replace(/[^0-9]/g, '')})
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>회사(법인)명<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={company} onChange={this.handleInputChange} name='company' />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>사업자등록증<span>*</span></label>
                                        <div className='card_row_val'>
                                            {Boolean(buss_file) && <label className='field_label flex-1'>{buss_file}</label>}
                                            <div className='btn_normal' onClick={() => $('input[name=buss_file]').trigger('click')}>수정</div>
                                            <input type="file" name="buss_file" onChange={(e) => this.handleFileChange(e, 'buss_path', 'buss_file')} className="d-none" accept="application/pdf, image/*" />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>브랜드 로고<span>*</span></label>
                                        <div className='card_row_val'>
                                            {Boolean(brand_file) && <label className='field_label flex-1'>{brand_file}</label>}
                                            <div className='btn_normal' onClick={() => $('input[name=brand_file]').trigger('click')}>수정</div>
                                            <input type="file" name="brand_file" onChange={(e) => this.handleFileChange(e, 'brand_path', 'brand_file')} className="d-none" accept="application/pdf, image/*" />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1 border-1' style={{ padding: 10 }}>
                                    <div className='frjustify_between w-100 p-3'>
                                        <div className='font_D700_16'>담당자 정보</div>
                                        <div className='field_label pe-4 me-3'>교육 문의를 받을 수 있는 담당자 정보를 입력하세요.</div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>운영 담당자명<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={manager_name} onChange={this.handleInputChange} name='manager_name' />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>운영 담당자 휴대전화번호<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={phone} onChange={this.handleInputChange} name='phone' />
                                        </div>
                                    </div>
                                    <div className='card_row'>
                                        <label className='field_label'>운영 담당자 이메일<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={m_email} onChange={this.handleInputChange} name='m_email' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(Editcompany);