import './top.css';
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { clearauth } from '../../redux/authacc/AuthaccActions';
import { showleftmenu } from '../../redux/leftmenu/LeftMenuActions';
import { formatNum, relativeTime, updateMyAlarm } from '../../libs/utils';
import {requestPost} from '../../libs/request';
import ConformModal from '../modal/ConformModal';

function Top() {
    const alermref = useRef(null);
    const mypageref = useRef(null);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const leftmenu = useSelector(state => state.leftmenu);
    const {alarm} = useSelector(state => state.authacc);
    const [openMypopup, setMypopup] = useState(false);
    const [openMyalerm, setMyalerm] = useState(false);
    const [modalIdx, setModalIdx] = useState(false);

    const handleClickAlermOutside = (event) => {
        if (alermref.current && !alermref.current.contains(event.target)) {
            setMyalerm(false);
        }
        if (mypageref.current && !mypageref.current.contains(event.target)) {
            setMypopup(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickAlermOutside, true);
        return () => {
            document.removeEventListener('click', handleClickAlermOutside, true);
        };
    }, []);

    const doLogout = ()=>{
        dispatch(clearauth());
        localStorage.clear();
        localStorage.removeItem('user_id');
        localStorage.removeItem('auth_token');
        window.location.href = '/login';
    }
    const goMyPage = ()=>{
        navigate("/mypage/changepassword");
    }

    const goDashBoard = ()=>{
        navigate("/dashboard");
    }

    const goAlermList = ()=>{
        navigate("/mypage/alermlist");
    }

    const openContentPage = (data) => {
        const {id, type, target_id} = data;

        requestPost('udpateAlarmRead', {id}, (res) => {
            updateMyAlarm()
        });
        // 
        const gotoPage = (link) => {
            setTimeout(() => {
                window.location.href = link;
            }, 1000);
        }
        // 
        if(type == 1) { // 관리자가 승인 대기 중인 교육 상품을 노출로 변경했을 때
            gotoPage(`/education/leasson/detail?id=${target_id}`);
        }
        else if(type == 2) { // 관리자가 교육 정보 수정을 승인했을 때
            gotoPage(`/education/leasson/detail?id=${target_id}`);
        }
        else if(type == 3) { // 관리자가 교육 정보 수정을 반려했을 때
            gotoPage(`/education/leasson/detail?id=${target_id}`);
        }
        else if(type == 4) { // 상품 문의가 신규 접수되었을 때
            gotoPage(`/education/queryprod/detail?id=${target_id}`);
        }
    }
    return (
        <>
        <div className="top_navi fix_top">
            <div className='menu_showbtn' onClick={()=>{
                dispatch(showleftmenu(!leftmenu))
            }}></div>
            <div className='top_head' onClick={goDashBoard}>
                <img src='/images/logo.png' className='head_mark' />
                <div className='head_user'>Partner</div>
            </div>
            <div style={{flex:1}} />
            <div className='top_tool'>
                <div className='topToolBtn'>
                    <div className='top_alerm' onClick={() => alarm?.alarmList?.length > 0 ? setMyalerm(!openMyalerm) : null}>
                        {alarm?.alarmCnt > 0 &&  <div className='top_alerm_count'>{formatNum(alarm?.alarmCnt)}</div>}
                    </div>
                    {openMyalerm && (<div className='popupRoot' ref={alermref}>
                        {
                            alarm?.alarmList.map((item, index) =>
                            <div className={`fcm_item ${item.read_flag == 0 ? 'on' : ''}`} onClick={() => openContentPage(item)} key={index}>
                                <div className='fcm_msg'>{item.title}</div>
                                <div className='fcm_time'>{relativeTime(item.reg_time)}</div>
                            </div>
                            )
                        }
                        <div className='all_list_view py-2' onClick={goAlermList}>알림 전체 보기</div>
                    </div>)}
                </div>
                <div className='topToolBtn'>
                    <div className='top_avatar' onClick={()=>{setMypopup(!openMypopup)}}>
                        
                    </div>
                    {openMypopup && (<div className='popupRoot profile' ref={mypageref}>
                        <div className='popupitem' onClick={goMyPage}>비밀번호변경</div>
                        <div className='popupitem' onClick={() => setModalIdx(true)}>로그아웃</div>
                    </div>)}
                </div>
            </div>
        </div>
        <div className="top_navi"></div>
        {modalIdx && <ConformModal closeModal={() => setModalIdx(false)} onConfirm={doLogout} title='로그아웃'>로그아웃하시겠습니까?</ConformModal>}
        </>
    );
}

export default Top;