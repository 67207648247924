import React from 'react'
import './dashboard.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import { formatDate, updateAdminPerm, updateMyAlarm } from '../../libs/utils';
import {requestPost} from '../../libs/request';
import consts from '../../libs/consts';
import Nodata from '../../components/Nodata';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {}, 
      dataList1: [], 
      dataList2: [], 
    };
  }

  componentDidMount(){
    setTimeout(() => {
      this.initDatas();
    }, 100);
  }
  initDatas = () => {
    updateAdminPerm();
    updateMyAlarm();

    requestPost('getDashboard', {}, (res) => {
      if(Boolean(res)) {
        const {data, dataList1, dataList2} = res;
        this.setState({data, dataList1, dataList2});
        // 
      }
    });
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  render() {
    const {data, dataList1, dataList2} = this.state;

    return (
      <Leftmenu>
        <div className='page_contents dashboard'>
          <div className='fralign_start' style={{ gap: 10, width:'100%' }}>
            <div className='card' style={{flex:1}}>
              <div className='card_head'>
                <div className='card_head_title'>회사 정보</div>
                <div className='more_btn' onClick={() => this.gotoPage('/company/detailcompany')}><img src="/images/ico_more.png" alt="" /> 더보기</div>
              </div>
              <div className='fralign_start' style={{ gap: 20 }}>
                {Boolean(data.thumb) ? <img src={consts.resourcUrl + data.thumb} className='company_img b-none' /> : <div className='company_img' />}
                <div className='company_name'>{data.user_name}</div>
              </div>
              <div className='fralign_start company_des'>
                {!data.intro ? '회사 소개가 등록되지 않았습니다. 소개글을 등록하고 사람들에게 우리 회사를 알려보세요.' : data.intro}
              </div>
            </div>
            <div className='card' style={{flex:1}}>
              <div className='card_head'>
                <div className='card_head_title'>모집 중인 교육</div>
                <div className='more_btn' onClick={() => this.gotoPage('/education/leasson')}><img src="/images/ico_more.png" alt="" /> 더보기</div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>교육상품명</th>
                    <th className='w_160'>모집마감일시</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                    dataList1.length > 0 ? dataList1.map((item, index) =>
                    <tr className='cursor_pointer' key={index} onClick={() => this.gotoPage('/education/leasson/detail?id=' + item.id)}>
                      <td>{item.title}</td>
                      <td>{formatDate(item.expire_time)}</td>
                    </tr>
                    )
                    :
                    <Nodata colSpan={2} />
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className='card'>
              <div className='card_head'>
                <div className='card_head_title'>상품 문의</div>
                <div className='more_btn' onClick={() => this.gotoPage('/education/queryprod')}><img src="/images/ico_more.png" alt="" /> 더보기</div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th >교육명</th>
                    <th >문의 내용</th>
                    <th className='w_100'>답변여부</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                    dataList2.length > 0 ? dataList2.map((item, index) =>
                    <tr className='cursor_pointer' key={index} onClick={() => this.gotoPage('/education/queryprod/detail?id=' + item.id)}>
                      <td>{item.title}</td>
                      <td>{Boolean(item.contents) && item.contents.length > consts.showMaxLen ? item.contents.substr(0, consts.showMaxLen)+'...' : item.contents}</td>
                      <td>{Boolean(item.answer_time) ? '답변완료' : '미완료'}</td>
                    </tr>
                    )
                    :
                    <Nodata colSpan={2} />
                  }
                </tbody>
              </table>
            </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(Dashboard);