// import './modal.css'
import React from 'react';
import consts from '../../libs/consts';
class SmartEditor extends React.Component {
    oEditors = [];
    constructor() {
        super();
        this.state = {
          editstep: 0,
          visible_state: 0,
          startdate: new Date(),
          finishdate: new Date(),
          starttime: null,
          appply_fee: 0,
          showjob:false,
          showtech:false,
          loaded: false
        };
    }
    componentDidMount(){
        const { editorid } = this.props;
        window.nhn.husky.EZCreator.createInIFrame({
            oAppRef: this.oEditors,
            elPlaceHolder: editorid,
            // sSkinURI: (consts.dev ? '' : window.location.origin + '/partner') + "/smarteditor2-2.10.0/dist/SmartEditor2Skin.html",
            sSkinURI: "/smarteditor2-2.10.0/dist/SmartEditor2Skin.html",
            fCreator: "createSEditor2"
          });
    }

    componentWillUnmount(){
        const { onChange, editorid } = this.props;
        if(this.oEditors.length > 0){
            this.oEditors.getById[editorid].exec("UPDATE_CONTENTS_FIELD", []);
            onChange(document.getElementById(editorid).value);
            document.querySelectorAll('iframe').forEach(iframe => iframe.remove());
        }
    }
    UNSAFE_componentWillReceiveProps(props) {

        if(!this.state.loaded) {
            setTimeout(() => {
              const { editorid } = this.props;
              this.oEditors.getById[editorid].exec("SET_IR", [props.value]); //내용초기화
              this.setState({loaded: true});
            }, 3000);
        }
    }

    getContext = ()=>{
        const { editorid } = this.props;
        if(this.oEditors.length > 0){
            this.oEditors.getById[editorid].exec("UPDATE_CONTENTS_FIELD", []);
            return document.getElementById(editorid).value;
        }
    }

    addContext = (html) => {
        const { editorid } = this.props;
        if(this.oEditors.length > 0){
            this.oEditors.getById[editorid].exec("PASTE_HTML", [html]);
        }
    }

    render(){
        const { value, editorid } = this.props;
        return (
            <textarea id={editorid} rows="10" style={{ width: '100%', height: 500 }} defaultValue={value} />
        );
    }
}

export default SmartEditor;