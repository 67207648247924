import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { Provider as StoreProvider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "@fontsource/noto-sans-kr";

function App() {
  const presistor = persistStore(store);
  return (
    <StoreProvider store={store}>
      <PersistGate
        // loading={<div className="spinner-border loader" style={{ position: "fixed", top: "50%", left: "50%", width: "5rem", height: "5rem" }} role="status"></div>}
        persistor={presistor}
      >
        {/* <BrowserRouter basename='partner'> */}
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
