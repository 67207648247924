import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';
Modal.setAppElement('#root');

function EduKeyword({ partnerId='', user_name='', afterOpenModal, closeModal, selectDatas }) {
    
	useEffect( () => {
		getData();
	}, []);
  
  	const [partner, setPartner] = useState({id: partnerId, user_name});
  	const [keyword, setKeyword] = useState('');
  	const [dataList, setDataList] = useState([]);

  	const getData = () => {
		requestPost('getPartnerByKeyword', {keyword}, (res) => {
		  if(Boolean(res)) {
			setDataList(res)
		  }
		});
	}
	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			getData();
		}
	}

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 500, height: 500, padding:0 }
            }}
        >
            <div className='card edukeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>교육사 선택</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='search_input' style={{width:'100%'}}>
                    <input type="text" placeholder='교육사명, 교육사ID로 검색' 
                        value={keyword}
						onKeyUp={handleKeyPress} 
						onChange={e => setKeyword(e.target.value) } 
                    />
                    <div className='search_btn' onClick={getData}></div>
                </div>
                <div className='card_body'>
                    <table>
                        <thead>
                            <tr>
                                <th>교육사 ID</th>
                                <th>교육사명</th>
                            </tr>
                        </thead>
                        <tbody>
							{
								dataList.length > 0 ? dataList.map((item, index) => 
								<tr key={index} className={item.id == partner.id ? 'sel_item' : ''}
									onClick={() => setPartner(item)}
								>
									<td>{item.id}</td>
									<td>{item.user_name}</td>
								</tr>
								)
								:
								<Nodata colSpan={2} />
							}
                        </tbody>
                    </table>
                </div>
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={() => {
						if(!partner.user_name) {
							return toastMsg('교육사를 선택하세요.', 'warning');
						}
                        selectDatas && selectDatas(partner);
                        closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
}

export default EduKeyword;