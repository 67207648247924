import axios from 'axios';
import consts from './consts';
import {getItem, itemKey} from './preference';
import { removeAllItem } from './utils';

/////////

export const requestPost = (url, body, callback) => {
  
  const user_id     = localStorage.getItem('user_id');
  const auth_token  = localStorage.getItem('auth_token');
  try {
    var xhr = new XMLHttpRequest();
    
    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200 || xhr.status === 201) {
              let retval = JSON.parse(xhr.responseText);
              if(retval == -101) {
                removeAllItem();
                setTimeout(() => {
                  window.location.href = '/login';
                }, 1000);
              }
              callback(retval)
            } else {
              callback(null)
            }
        }
    };
    xhr.open('POST', `${consts.apiBasicUrl}partner/${url}`);
    
    body = JSON.stringify(body);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    // let token  = getItem(itemKey.token);
    // let userId = getItem(itemKey.userId);
    xhr.setRequestHeader('x-access-token', auth_token);
    xhr.setRequestHeader('x-access-id', user_id);
    xhr.send(body);

  } catch (error) {
      console.log(error)
  }
};

export const requestFile = (url, formData, callback) => {
  try {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', `${consts.apiBasicUrl}${url}`);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 201) {
          callback(JSON.parse(xhr.responseText))
        } else {
          callback(null)
        }
      }
    };

    // xhr.setRequestHeader('Access-Origin', `${consts.apiBasicUrl}`);
    xhr.send(formData);

  } catch (error) {
    console.log(error)
  }
};

export const nonAuthedReqPost = (url, body, callback) => {
  try {
    var xhr = new XMLHttpRequest();
    
    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200 || xhr.status === 201) {
              callback(JSON.parse(xhr.responseText))
            } else {
              callback(null)
            }
        }
    };
    xhr.open('POST', `${consts.apiBasicUrl}${url}`);
    
    body = JSON.stringify(body);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.send(body);

  } catch (error) {
    console.log(error)
  }

};

const getfilename = function (url) {
  return url.substring(url.lastIndexOf('/')+1);
}

export const fileDownload = (url, filename='') => {
  axios({
        url,
        method: "GET",
        // headers: headers,
        responseType: "blob" // important
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            filename
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}
export const fileDownload2 = (url, filename='') => {
  axios.get(url, { responseType: 'blob' })
  .then(response => {
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    if(filename === "")
    {
      link.download = getfilename(url);
    }
    else{
      link.download = filename;
    }
    link.click()
    URL.revokeObjectURL(link.href)
  }).catch(console.error)
  // const link = document.createElement('a');
  // link.url = url;
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);

};
