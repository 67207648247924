import './menu.css'
import Menugroup from '../menugroup';
import Menuitem from '../menuitem';
function Menu() {
    return (
        <div className="menu_root">
            <Menugroup title={"대시보드"} linkurl={"/dashboard"} baseurl={"/dashboard"}/>
            <Menugroup title={"교육관리"} linkurl={"/education/leasson"} baseurl={"/education"}>
                <Menuitem title={"교육상품"} linkurl={"/education/leasson"}/>
                <Menuitem title={"상품문의/답변"} linkurl={"/education/queryprod"}/>
            </Menugroup>
            <Menugroup title={"회사관리"} linkurl={"/company/detailcompany"} baseurl={"/company"}>
                <Menuitem title={"회사정보"} linkurl={"/company/detailcompany"}/>
                <Menuitem title={"한줄톡"} linkurl={"/company/linetok"}/>
                <Menuitem title={"계정관리"} linkurl={"/company/manager/list"}/>
            </Menugroup>
            <Menugroup title={"CRM"} linkurl={"/crm/reviews"} baseurl={"/crm"}>
                <Menuitem title={"수강후기"} linkurl={"/crm/reviews"}/>
                <Menuitem title={"프리미엄 수강후기"} linkurl={"/crm/premiumReviews"}/>
            </Menugroup>
        </div>
    );
}

export default Menu;