import './manager.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';

class ManagerList extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      type:         '',
      status:       0,
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    const partnerId = localStorage.getItem('user_id');
    updateAdminPerm();
    updateMyAlarm();

    this.getData(partnerId);
  }

  getData = (partnerId, currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, status, type } = this.state;
    const params = { keyword, status, type, currentPage, partnerId }
    requestPost('getManagerList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      const partnerId = localStorage.getItem('user_id');
      this.getData(partnerId, 1);
    }
  }
  changeStatus = (status) => {
    this.setState({status})
    setTimeout(() => {
      const partnerId = localStorage.getItem('user_id');
      this.getData(partnerId, 1);
    }, 10);
  }
  changeType = (type) => {
    this.setState({type});
    setTimeout(() => {
      const partnerId = localStorage.getItem('user_id');
      this.getData(partnerId, 1);
    }, 100);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  openDetail = (id)=>{
    const { router } = this.props;
    router.navigate('/company/manager/list/detail?id='+id);
  }

  render() {
    const { keyword, type, status, totalCnt, currentPage, dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents mnglist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>관리자 계정 관리</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='이름, 이메일로 검색'
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => {const partnerId = localStorage.getItem('user_id');this.getData(partnerId, 1)}} />
                </div>
                <div className='btn_normal' onClick={() => {const partnerId = localStorage.getItem('user_id'); this.gotoPage(`/company/manager/list/detail?id=${(partnerId * -1)}`)}}>계정 초대</div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
              <span>{formatNum(totalCnt)}</span>개의 계정
              </div>
              <div className='fralign_center' style={{ gap: 60 }}>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>활성상태</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0)}><input type="radio" name="status" checked={status == 0} onChange={()=>{}}/>전체</label>
                  <label className='radio_label' onClick={() => this.changeStatus(1)}><input type="radio" name="status" checked={status == 1} onChange={()=>{}}/>활성</label>
                  <label className='radio_label' onClick={() => this.changeStatus(2)}><input type="radio" name="status" checked={status == 2} onChange={()=>{}}/>비활성</label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>계정ID</th>
                  <th>이메일(로그인ID)</th>
                  <th>이름</th>
                  <th>생성일시</th>
                  <th>활성상태</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={() => this.openDetail(item.id)}>
                    <td>{item.id}</td>
                    <td>{item.email}</td>
                    <td>{item.user_name}</td>
                    <td>{formatDate(item.reg_time)}</td>
                    <td>{item.status == 1 ? '활성' : '비활성' }</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => {const partnerId = localStorage.getItem('user_id'); this.getData(partnerId, pageIdx)}} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(ManagerList);