import React from 'react';
import './login.css';
import withRouter from '../../components/withRouter';
import {validationEmail} from '../../libs/utils';
import {nonAuthedReqPost} from '../../libs/request';

class FindPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      email_msg:  '',
      emailsend:false,
    };
  }

  onClickFindPassword = () => {
    const {email, password} = this.state;

    if(email === '') {
      return this.setState({email_msg: '아이디를 입력하세요.'});
    }
    else if(!validationEmail(email)) {
      this.setState({email_msg: '이메일 형식이 올바르지 않습니다.'}); return;
    }
    else 
      this.setState({email_msg: ''});
    
    nonAuthedReqPost('updatePassSendEmail', {type: 'partner', email}, (res) => {
      if(!res) {
        return this.setState({email_msg: '등록되지 않은 아이디입니다. 입력 정보를 다시 한번 확인해주세요.'});
      }
      else {
        this.setState({emailsend:true});
      }
    });
    
  }

  onClickLogin = ()=>{
    const { router } = this.props;
    router.navigate("/login");
  }

  render() {
    const { email, email_msg, emailsend } = this.state;

    return (
      <div className="loginpage">
        {emailsend === false && (<div className='loginform'>
          <div className='formtitle'>비밀번호 찾기</div>
          <div className='findpagedes'>비밀번호 찾기를 위해 아이디를 입력하세요.</div>
          <div className='login_inp_area'>
            <input type="text" className='input_box' placeholder='아이디를 입력하세요.' onChange={(e)=>{ this.setState({email:e.target.value}) }} value={email} />
            {Boolean(email_msg) && <div className="invalid-feedback d-block">{email_msg}</div>}
          </div>
          <div className='login_tool'>
            <div className='btn_rectangle' onClick={this.onClickFindPassword}>확인</div>
            <div className='btn_findpsw' onClick={this.onClickLogin}>로그인</div>
          </div>
        </div>)}
        {emailsend === true && (<div className='loginform'>
          <div className='emailtitle'>비밀번호 재설정을 위한 {'\n'}메일이 전송되었습니다.</div>
          <div className='findpagedes'>메일에 포함된 링크를 통해 비밀번호를 다시 설정해주세요.</div>
          <div className='login_tool'>
            <div className='btn_rectangle' onClick={this.onClickLogin}>로그인 페이지로 이동</div>
          </div>
        </div>)}
      </div>
    );
  }
}

export default (withRouter(FindPassword));