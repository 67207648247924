import React from 'react';
import './education.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import Datepicker from '../../components/datepicker';
import Timepicker from '../../components/datepicker/timepicker';
import EduKeyword from '../../components/modal/edu_keyword';
import JobKeyword from '../../components/modal/job_keyword';
import TechKeyword from '../../components/modal/tech_keyword';
import KeywordsModal from '../../components/modal/KeywordsModal';
import QueryPreView from '../../components/modal/query_prevView';
import ConformModal from '../../components/modal/ConformModal';
import EditAnswer from '../../components/modal/edit_answer';
import SmartEditor from '../../components/smarteditor';
import * as xlsx from 'xlsx';
import $ from 'jquery';
import { requestPost, requestFile, nonAuthedReqPost } from '../../libs/request';
import {
  formatDate2,
  getEduDays,
  toastMsg,
  getCurrentTime,
  formatTime,
  isEmptyObj,
} from '../../libs/utils';
import consts from '../../libs/consts';
import LessonReviewModal from '../../components/modal/LessonReviewModal';
import { saveAs } from 'file-saver';

class NewLeasson extends React.Component {
  editorref = null;
  fileref = null;
  excelRef = null;
  constructor() {
    super();
    this.state = {
      reg: '', // 등록상태
      productId: '',
      support_price: 0,
      modalIdx: 0,
      set_status: 5,
      areaList: [],
      areaSubList: [],
      confirmTitle: '',
      confirmTxt: '',
      hiData: {},
      editstep: 1,

      // detail
      user_name: '',
      title: '',
      thumb: '',
      st_date: new Date(),
      ed_date: new Date(),
      expire_date: new Date(),
      expire_time: new Date(),
      no_limit_time1: 0,
      no_limit_time2: 0,
      price_type: 1,
      price: '0',
      cond_txt: '',
      support_price: -1,
      method: 1,
      area1: 1,
      area2: 1,
      schedule: '',
      req_url: '',
      comment: '',
      note: '',
      jobtype: '',
      tech_stack: '',
      difficulty: '',
      interest: '',
      customer_type: '',
      curriclum: '[]',
      lesson_review: '',
      faq: '',
      intro: '',
      return_txt: '',
      updated: '',
      status: 5,
      reg_time: '',

      review_input: false,
      review_title: '',
      review_link: '',
      timeout_yn: 'Y',
    };
  }

  componentDidMount() {
    const { searchParams } = this.props.router;
    let productId = searchParams.get('id');
    let step = searchParams.get('step');
    let reg = searchParams.get('reg');
    productId = Boolean(productId) ? productId : '';
    if (step > 0) {
      this.setState({ editstep: parseInt(step) });
    }
    if (Boolean(reg)) {
      this.setState({ reg });
    }
    //
    if (productId) {
      this.getData(productId);
    } else {
      // register
      this.getArea();
    }
  }

  getArea = (area_id = 1) => {
    nonAuthedReqPost('getAreas', {}, (res) => {
      if (Boolean(res)) {
        this.setState({ areaList: res });
      }
    });
    nonAuthedReqPost('getSubAreaInfo', { area_id }, (res) => {
      if (Boolean(res)) {
        this.setState({ areaSubList: res });
      }
    });
  };
  getData = (productId) => {
    window.scrollTo(0, 0);
    this.setState({ productId });

    const initDatas = (res) => {
      const {
        timeout_yn,
        user_name,
        title,
        thumb,
        st_date,
        ed_date,
        expire_time,
        no_limit_time1,
        no_limit_time2,
        price_type,
        price,
        cond_txt,
        support_price,
        method,
        area1,
        area2,
        schedule,
        req_url,
        comment,
        note,
        jobtype,
        tech_stack,
        difficulty,
        interest,
        customer_type,
        curriclum,
        curriclum_time,
        lesson_review,
        faq,
        intro,
        return_txt,
        updated,
        status,
        reg_time,
      } = res;

      this.setState({
        timeout_yn,
        user_name,
        title,
        thumb,
        no_limit_time1,
        no_limit_time2,
        price_type,
        price,
        cond_txt,
        support_price,
        method,
        area1,
        area2,
        schedule,
        req_url,
        jobtype,
        tech_stack,
        difficulty,
        interest,
        customer_type,
        curriclum_time,
        updated,
        status,
        reg_time,
        set_status: status,
        intro: Boolean(intro) ? intro : '',
        return_txt: Boolean(return_txt) ? return_txt : '',
        comment: Boolean(comment) ? comment : '',
        note: Boolean(note) ? note : '',
        curriclum: Boolean(curriclum) ? curriclum : '',
        lesson_review: Boolean(lesson_review) ? lesson_review : '',
        faq: Boolean(faq) ? faq : '',
        st_date: new Date(st_date),
        ed_date: new Date(ed_date),
        expire_date: new Date(expire_time),
        expire_time: new Date(expire_time),
      });
      // 지역 리스트 불러오기
      this.getArea(area1);
    };
    try {
      if (productId > 0) {
        console.log('YO?');
        // 임시 저장된 데이터가 있는가?
        requestPost('getProductDetailTmp', { productId }, (res) => {
          console.log(res);
          if (Boolean(res)) {
            initDatas(res);
          } else {
            // get org data
            requestPost(
              'getProductDetail',
              { productId, type: 'partner' },
              (org_data) => {
                if (Boolean(org_data)) {
                  initDatas(org_data);
                } else {
                  alert('삭제된 내역입니다.');
                  this.gotoPage(-1);
                }
              }
            );
          }
        });
      }
    } catch (error) {}
  };

  openfileSelect = () => {
    if (this.fileref != null) {
      this.fileref.click();
    }
  };

  openExcelSelect = () => {
    if (this.excelRef != null) {
      this.excelRef.click();
    }
  };

  handleFileChange = async (event) => {
    let file = event.target.files[0];
    // get dementions
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    let width = img.width;
    let height = img.height;

    if (width != 720 || height != 378) {
      toastMsg('이미지를 720px X 378px로 권장 드립니다.');
    }

    const formData = new FormData();
    formData.append('file', file);
    requestFile('uploadImage', formData, (res) => {
      console.log(res);
      this.setState({ thumb: res.file_path });
    });
  };

  handleImgFileChange = (event) => {
    let file = event.target.files[0];
    if(!file) {
      return;
    }
    const formData = new FormData(); 
    formData.append( "file",  file);
    requestFile('uploadImage', formData, (res) => {
      const {file_path, originalname} = res;

  let imgHTML = `<img src="${consts.resourcUrl + file_path}" style="width: 100%">`;
  this.editorref.addContext(imgHTML);
    });
  }

  handleInputChange = (event) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({ [name]: value });
    //
    if (name == 'price_type' && value != 3) {
      //가격 유형 유료가 아니면
      this.setState({ price: 0 });
    }
    if (name == 'area1') {
      // 지역 변경
      nonAuthedReqPost('getSubAreaInfo', { area_id: value }, (res) => {
        if (Boolean(res)) {
          this.setState({ areaSubList: res, area2: res[0]?.id });
        }
      });
    }
  };

  openReqUrl = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  };
  renderKeyword = ({ data }) => {
    if (!data) return '';
    data = data.split(',');

    return data.map((item, index) => (
      <div key={index} className="btn_option">
        {item}
      </div>
    ));
  };

  // 커리큘럼 삭제
  removeCurriclum = (index) => {
    let { curriclum } = this.state;
    let curriclumList = Boolean(curriclum) ? JSON.parse(curriclum) : [];
    curriclumList.splice(index, 1);
    this.setState({ curriclum: JSON.stringify(curriclumList) });
  };

  update = (set_status, next = false) => {
    const {
      expire_date,
      expire_time,
      productId,
      editstep,
      timeout_yn,
      title,
      thumb,
      st_date,
      ed_date,
      no_limit_time1,
      no_limit_time2,
      price_type,
      price,
      cond_txt,
      support_price,
      method,
      area1,
      area2,
      schedule,
      req_url,
      comment,
      intro,
      note,
      jobtype,
      tech_stack,
      difficulty,
      interest,
      customer_type,
      curriclum,
      curriclum_time,
      lesson_review,
      faq,
      return_txt,
      status,
    } = this.state;
    let intro1 = this.editorref?.getContext();

    let hiData = {};
    if (set_status != 5 && editstep == 3) {
      // 임시저장이 아닐 때 에만 필수 입력 항목의 입력 여부를 체크
      if (!title) {
        hiData.title = 1;
      }
      if (!thumb) {
        hiData.thumb = 1;
      }
      if (price_type == 3 && price == 0) {
        // 가격유형 - 유료
        hiData.price = 1;
      }
      if (price_type == 2 && !cond_txt) {
        // 조건부 무료
        hiData.cond_txt = 1;
      }
      if (!support_price) {
        hiData.support_price = 1;
      }
      if (!schedule) {
        hiData.schedule = 1;
      }
      if (!req_url) {
        hiData.req_url = 1;
      }
      if (!jobtype) {
        hiData.jobtype = 1;
      }
      if (!difficulty) {
        hiData.difficulty = 1;
      }
      if (!interest) {
        hiData.interest = 1;
      }
      if (!customer_type) {
        hiData.customer_type = 1;
      }
      if (!curriclum) {
        hiData.curriclum = 1;
      }
      //
      this.setState({ hiData });
      if (!isEmptyObj(hiData)) {
        // 필수 항목 미 입력
        return toastMsg('필수 입력 항목들을 입력하세요.');
      }
    } else {
      this.setState({ hiData });
    }
    // 1. curriculum Loop
    //    1. data loop
    //       1. if data has title or time, make error.
    //       2. if data does not have title and time, remove it.
    //    2. if there is data, make error.
    //    3. if there is no data, check time and title.
    //    4. if there is title or time, make error
    //    5. else, remove item.
    // 2. if curriculum is empty, make Error.
    // const filteredCurriclum = [];

    // Loop through the data and add non-empty/non-null objects to filteredData
    const filteredCurriclum =
      editstep == 3
        ? JSON.parse(curriclum).filter((item) => {
            const filteredData = item.data.filter(
              (subitem) =>
                !Object.values(subitem).every((value) => value === '')
            );

            // Remove the "data" key if all its subitems were empty
            if (filteredData.length === 0) {
              delete item.data;
            } else {
              item.data = filteredData;
            }

            return item;
          })
        : {};
    const stringCurriculum = JSON.stringify(filteredCurriclum);

    const reg_id = localStorage.getItem('account_id');
    const params = {
      productId,
      editstep,
      data: {
        timeout_yn,
        title,
        thumb,
        no_limit_time1,
        no_limit_time2,
        price_type,
        price,
        cond_txt,
        support_price,
        method,
        area1,
        area2,
        schedule,
        req_url,
        comment,
        note,
        jobtype,
        tech_stack,
        difficulty,
        interest,
        customer_type,
        curriclum_time,
        return_txt,
        intro,
        set_status,
        status,
        reg_id,
        intro: Boolean(intro1) ? intro1 : intro,
        curriclum: Boolean(stringCurriculum)
          ? JSON.stringify(stringCurriculum)
          : '',
        lesson_review: Boolean(lesson_review)
          ? JSON.stringify(lesson_review)
          : '',
        faq: Boolean(faq) ? JSON.stringify(faq) : '',
        st_date: formatDate2(st_date),
        ed_date: formatDate2(ed_date),
        expire_time:
          formatDate2(expire_date) + ' ' + formatTime(expire_time) + ':00',
      },
    };

    console.log(params);

    if (set_status == 5) {
      // 임시저장
      requestPost('updateProductTmp', params, (res) => {
        if (Boolean(res)) {
          if (!productId) {
            this.setState({ productId: res });
            // setTimeout(() => {
            //   // window.location.href = `/education/leasson/new?id=${res}&reg=1${next ? '&step='+(editstep + 1) : ''}`;
            //   // window.location.href = `/education/leasson/new?id=${res}&reg=1&step=${editstep}`;
            // }, 100);
          } else {
            toastMsg('저장되었습니다.', 'success');
            this.setState({ status: set_status, set_status });
          }
        } else {
          toastMsg('저장에 실패하였습니다.');
        }
      });

      // let products = localStorage.getItem('productList') || "[]";
      // const productList = JSON.parse(products);
      // if(productList.find(dt => dt.productId == productId)) {

      // }
    } else {
      // status = 4 OR 1

      requestPost('updateProductDetail', params, (res) => {
        if (Boolean(res)) {
          // if(!productId) {
          //   setTimeout(() => {
          //     this.props.router.navigate('/education/leasson');
          //   }, 100);
          // }
          // else {
          toastMsg('저장되었습니다.', 'success');
          this.setState({ status: set_status, set_status });

          // if(set_status == 1 && editstep == 3) {    // 등록 완료
          this.setState({ editstep: 4 });
          // }
          // }
        } else {
          toastMsg('저장에 실패하였습니다.');
        }
      });
    }
  };
  readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = xlsx.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      let tempCurri = [];
      let tempCurriIdx = -1;
      for (let i = 0; i < json.length; i++) {
        if (json[i]['구분'] === '대주제') {
          tempCurri.push({
            title: json[i]['주제'],
            time: json[i]['시간'],
            data: [],
          });
          tempCurriIdx++;
        } else {
          tempCurri[tempCurriIdx].data.push({
            title: json[i]['주제'],
            time: json[i]['시간'],
          });
        }
      }

      console.log(tempCurri);
      this.setState({ curriclum: JSON.stringify(tempCurri) });
    };
    reader.readAsArrayBuffer(file);
  };

  // 상품 삭제  // 임시저장일 때 만 삭제 있음
  deleteProduct = () => {
    const { productId } = this.state;

    requestPost('deleteProduct', { productId, type: 'delete' }, (res) => {
      if (Boolean(res)) {
        this.props.router.navigate('/education/leasson');
      }
    });
  };
  //
  onConfirm = () => {
    const { modalIdx } = this.state;
    this.setState({ modalIdx: 0, confirmTxt: '' });

    if (modalIdx == 10) {
      // 상품 삭제
      this.deleteProduct();
    }
  };

  goPrevStep = () => {
    let { editstep } = this.state;
    editstep--;
    if (editstep < 1) {
      //
      this.gotoPage(-1);
    } else {
      this.setState({ editstep });
    }
  };
  goNextStep = () => {
    let {
      set_status,
      productId,
      editstep,
      timeout_yn,
      title,
      thumb,
      st_date,
      ed_date,
      price_type,
      price,
      cond_txt,
      support_price,
      method,
      area1,
      area2,
      schedule,
      req_url,
    } = this.state;

    let hiData = {};
    if (true) {
      if (!title) {
        hiData.title = 1;
      }
      if (!thumb) {
        hiData.thumb = 1;
      }
      if (price_type == 3 && price == 0) {
        // 가격유형 - 유료
        hiData.price = 1;
      }
      if (price_type == 2 && !cond_txt) {
        // 조건부 무료
        hiData.cond_txt = 1;
      }
      if (!support_price) {
        hiData.support_price = 1;
      }
      if (!schedule) {
        hiData.schedule = 1;
      }
      if (!req_url) {
        hiData.req_url = 1;
      }
      //
      this.setState({ hiData });
      if (!isEmptyObj(hiData)) {
        // 필수 항목 미 입력
        return toastMsg('필수 입력 항목들을 입력하세요.');
      }
    }
    //
    // if((!Boolean(productId) || set_status == 5) && editstep != 3) {   // 다음 버튼 누를 때 임시저장
    //   this.update(5, true);
    // }
    //
    editstep++;
    if (editstep == 4) {
      // 수정 / 등록
      let set_status = Boolean(productId) ? 4 : 1;
      this.update(set_status);
      return;
    } else {
      this.setState({ editstep });
    }
    //
    if (editstep == 3) {
      let intro = this.editorref?.getContext();
      this.setState({ intro });
    }
  };

  downloadTemplate() {
    saveAs('/doc/upload_template.xlsx', '엑셀업로드템플릿.xlsx');
  }

  render() {
    const {
      timeout_yn,
      productId,
      expire_date,
      expire_time,
      set_status,
      modalIdx,
      areaList,
      areaSubList,
      confirmTitle,
      confirmTxt,
      editstep,
      reg,
      review_input,
      review_title,
      review_link,
    } = this.state;
    const {
      user_name,
      title,
      thumb,
      st_date,
      ed_date,
      no_limit_time1,
      no_limit_time2,
      price_type,
      price,
      cond_txt,
      support_price,
      method,
      area1,
      area2,
      schedule,
      req_url,
      comment,
      note,
      jobtype,
      tech_stack,
      difficulty,
      interest,
      customer_type,
      curriclum,
      curriclum_time,
      lesson_review,
      faq,
      intro,
      return_txt,
    } = this.state;

    const cur_time = getCurrentTime();
    const exp_time =
      formatDate2(expire_date) + ' ' + formatTime(expire_time) + ':00'; // 모집마감일시
    //
    let curriclumList = [];
    let faqList = [];
    let reviewList = [];
    try {
      curriclumList = Boolean(curriclum) ? JSON.parse(curriclum) : [];
      faqList = Boolean(faq) ? JSON.parse(faq) : [];
      reviewList = Boolean(lesson_review) ? JSON.parse(lesson_review) : [];
    } catch (error) {
      console.error(error);
    }

    return (
      <Leftmenu>
        <div className="page_contents detailleasson mb-5">
          <div className="card">
            {editstep < 4 && (
              <div className="card_head">
                <div style={{display:'flex', gap:'15px', alignItems:'flex-end'}}>
                  <div className="card_head_title">교육 상품 상세</div>
                  <div style={{ color:'red', fontWeight:'600', fontSize:'14px'}}>각 페이지마다 임시저장을 꼭 눌러주세요!</div>
                </div>
                <div className="card_head_tool" style={{ gap: 40 }}>
                  <div className="step_row">
                    <div className="act_step_num">1</div>
                    <div className="split" />
                    <div
                      className={editstep > 1 ? 'act_step_num' : 'def_step_num'}
                    >
                      2
                    </div>
                    <div className="split" />
                    <div
                      className={editstep > 2 ? 'act_step_num' : 'def_step_num'}
                    >
                      3
                    </div>
                  </div>
                  <div className="fralign_center" style={{ gap: 10 }}>
                    {Boolean(productId) && (
                      <div
                        className="btn_link"
                        onClick={() =>
                          window.open(
                            consts.domain + 'edudetail?type=1&id=' + productId, "_blank"
                          )
                        }
                      >
                        미리보기
                      </div>
                    )}
                    {(!Boolean(productId) || set_status == 5) && (
                      <div className="btn_link" onClick={() => this.update(5)}>
                        임시저장
                      </div>
                    )}
                    <div
                      className="btn_normal cancel"
                      onClick={this.goPrevStep}
                    >
                      이전
                    </div>
                    <div className="btn_normal" onClick={this.goNextStep}>
                      {editstep === 3
                        ? !Boolean(productId) || Boolean(reg) || set_status == 5
                          ? '등록'
                          : '수정'
                        : '다음'}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="card_body">
              {editstep == 1 && (
                <>
                  <div
                    className="frjustify_between splite_head"
                    style={{ width: '100%' }}
                  >
                    <div className="font_D700_16">기본 정보</div>
                    <div className="field_label">
                      <span>*</span> 필수 입력 항목입니다.
                    </div>
                  </div>
                  <div className="card_row" style={{ alignItems: 'stretch' }}>
                    <div style={{ flex: 1 }}>
                      <div className="card_row">
                        <label className="field_label">
                          교육상품명<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <input
                            type="text"
                            className="input_box"
                            placeholder="상품명을 입력하세요."
                            value={title}
                            style={{ width: 330 }}
                            onChange={this.handleInputChange}
                            name="title"
                          />
                        </div>
                      </div>
                      <div className="card_row">
                        <label className="field_label">
                          교육시작/종료일<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <Datepicker
                            selected={st_date}
                            disabled={no_limit_time1 == 1}
                            onChange={(date) => {
                              if (date > ed_date) {
                                this.setState({ st_date: date, ed_date: date });
                              } else this.setState({ st_date: date });
                            }}
                          />
                          <Datepicker
                            selected={ed_date}
                            onChange={(date) =>
                              this.setState({ ed_date: date })
                            }
                            minDate={st_date}
                            disabled={no_limit_time1 == 1}
                          />
                          <span>{getEduDays(st_date, ed_date)}</span>
                          <label className="radio_label" style={{ width: 150 }}>
                            <input
                              type="checkbox"
                              checked={no_limit_time1}
                              onChange={() => {}}
                              onClick={() =>
                                this.setState({
                                  no_limit_time1: no_limit_time1 == 1 ? 0 : 1,
                                })
                              }
                            />
                            제한 없음
                          </label>
                        </div>
                      </div>
                      <div className="card_row">
                        <label className="field_label">
                          모집마감일시<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <Datepicker
                            selected={expire_date}
                            onChange={(date) =>
                              this.setState({ expire_date: date })
                            }
                            disabled={no_limit_time2 == 1}
                          />
                          <Timepicker
                            selected={expire_time}
                            onChange={(val) =>
                              this.setState({ expire_time: val })
                            }
                            disabled={no_limit_time2 == 1}
                          />
                          <span>
                            {cur_time < exp_time ? '모집중' : '모집종료'}
                          </span>
                          <label className="radio_label" style={{ width: 150 }}>
                            <input
                              type="checkbox"
                              checked={no_limit_time2}
                              onChange={() => {}}
                              onClick={() =>
                                this.setState({
                                  no_limit_time2: no_limit_time2 == 1 ? 0 : 1,
                                })
                              }
                            />
                            상시
                          </label>
                        </div>
                      </div>
                      <div className="card_row">
                        <label className="field_label">
                          타임아웃배너<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <label
                            className="radio_label"
                            onClick={() => this.setState({ timeout_yn: 'Y' })}
                          >
                            <input
                              type="radio"
                              name="timeout_yn"
                              checked={timeout_yn === 'Y'}
                              onChange={() => {}}
                            />
                            있음
                          </label>
                          <label
                            className="radio_label"
                            onClick={() => this.setState({ timeout_yn: 'N' })}
                          >
                            <input
                              type="radio"
                              name="timeout_yn"
                              checked={timeout_yn === 'N'}
                              onChange={() => {}}
                            />
                            없음
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fcjust_center"
                      style={{
                        width: 300,
                        alignItems: 'center',
                        borderLeft: '1px solid #EEEEEE',
                        padding: 10,
                        gap: 6,
                        boxSizing: 'border-box',
                      }}
                    >
                      <label className="field_label w-100">
                        상품이미지<span>*</span>{' '}
                        <span className="gray-text fs-11 ms-2 mt--2">
                          png, jpg 파일, 720px X 378px 권장
                        </span>
                      </label>
                      <input
                        type="file"
                        ref={(val) => (this.fileref = val)}
                        name="thumb"
                        onChange={this.handleFileChange}
                        className="d-none"
                        accept="image/*"
                      />
                      {Boolean(thumb) ? (
                        <img
                          src={consts.resourcUrl + thumb}
                          className="prod_img_bg"
                        />
                      ) : (
                        <div
                          className="prod_img_bg"
                          style={{ height: '100%' }}
                        />
                      )}
                      <div className="btn_normal" onClick={this.openfileSelect}>
                        이미지등록
                      </div>
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      가격유형<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <select
                        value={price_type}
                        onChange={this.handleInputChange}
                        name="price_type"
                        placeholder="가격 유형을 선택하세요"
                      >
                        <option value={1}>무료</option>
                        <option value={2}>조건부 무료</option>
                        <option value={3}>유료</option>
                      </select>
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      가격<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <input
                        type="number"
                        disabled={price_type != 3}
                        value={price == 0 ? '' : price}
                        onChange={this.handleInputChange}
                        name="price"
                      />
                    </div>
                  </div>
                  {price_type == 2 && (
                    <div className="card_row">
                      <label className="field_label">
                        조건입력<span>*</span>
                      </label>
                      <div className="card_row_val">
                        <input
                          type="text"
                          style={{ width: 800 }}
                          value={cond_txt}
                          onChange={this.handleInputChange}
                          name="cond_txt"
                        />
                      </div>
                    </div>
                  )}
                  <div className="card_row">
                    <label className="field_label">
                      지원료<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ support_price: -1 })}
                      >
                        <input
                          type="radio"
                          name="support_price"
                          checked={support_price === -1}
                          onChange={() => {}}
                        />
                        없음
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ support_price: 0 })}
                      >
                        <input
                          type="radio"
                          name="support_price"
                          checked={support_price >= 0}
                          onChange={() => {}}
                        />
                        있음
                      </label>
                      <input
                        type="number"
                        disabled={support_price == -1}
                        value={support_price > 0 ? support_price : ''}
                        onChange={this.handleInputChange}
                        name="support_price"
                      />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      학습방식<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <select
                        value={method}
                        onChange={this.handleInputChange}
                        name="method"
                        placeholder="학습 방식을 선택하세요"
                      >
                        <option value={1}>실시간 온라인</option>
                        <option value={2}>오프라인</option>
                        <option value={3}>이러닝</option>
                        <option value={4}>블렌디드</option>
                      </select>
                    </div>
                  </div>
                  {(method == 2 || method == 4) && (
                    <div className="card_row">
                      <label className="field_label">
                        지역<span>*</span>
                      </label>
                      <div className="card_row_val">
                        <select
                          value={area1}
                          onChange={this.handleInputChange}
                          name="area1"
                        >
                          {areaList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <select
                          value={area2}
                          onChange={this.handleInputChange}
                          name="area2"
                        >
                          {areaSubList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {' '}
                              {`${item.sigungu} ${item.dong}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="card_row">
                    <label className="field_label">
                      수업일정<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <input
                        type="text"
                        style={{ width: 400 }}
                        value={schedule}
                        onChange={this.handleInputChange}
                        name="schedule"
                        placeholder="수업 일정을 입력하세요. ex) 월~금, 1일 평균 8시간"
                      />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      교육신청URL<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <input
                        type="text"
                        style={{ width: 500 }}
                        value={req_url}
                        name="req_url"
                        onChange={this.handleInputChange}
                      />
                      {Boolean(req_url) && (
                        <div
                          className="btn_link"
                          onClick={() => this.openReqUrl(req_url)}
                        >
                          새 창으로 열기
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className='card_row'>
                <label className='field_label'>에디터스코멘트<span>*</span></label>
                <div className='card_row_val'>
                  <textarea style={{ width: '100%' }} placeholder="에디터스코멘트을 입력하세요." value={comment} onChange={this.handleInputChange} name='comment' />
                </div>
              </div> */}
                  <div className="card_row">
                    <label className="field_label">유의사항</label>
                    <div className="card_row_val">
                      <textarea
                        style={{ width: '100%' }}
                        placeholder="유의사항을 입력하세요."
                        value={note}
                        onChange={this.handleInputChange}
                        name="note"
                      />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">에디터스코멘트</label>
                    <div className="card_row_val">
                      <textarea
                        style={{ width: '100%' }}
                        rows={15}
                        placeholder="에디터스 픽! 작성 요령&#10;- 에디터스 픽은 상세페이지 상단에 위치하여 해당 부트캠프의 특장점을 사용자들에게 요약하여 보여주는 탭이에요!&#10;- 최소 50자에서 최대 100자! 가독성을 위해 주어진 범위에 맞게 작성해주세요.&#10;- 내부 규정에 맞지 않을 경우 임의로 수정될 수 있습니다."
                        value={comment}
                        onChange={this.handleInputChange}
                        name="comment"
                      />
                    </div>
                  </div>
                </>
              )}

              {editstep == 2 && (
                <>
                  <div
                    className="frjustify_between splite_block"
                    style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}
                  >
                    <div className="font_D700_16">클래스 소개</div>
                  </div>
                  <div className='card_row_val'>
                      <div className='btn_normal' onClick={() => $('input[name=thumbinfo]').trigger('click')}>이미지 추가</div>
                      <span className='gray-text fs-11 ms-2 mt--2'>png, jpg 파일, 1280px X 672px 권장</span>
                       <input type="file" name="thumbinfo" onChange={(e) => this.handleImgFileChange(e)} className="d-none" accept="image/*" />
                  </div>
                  <SmartEditor
                    ref={(val) => (this.editorref = val)}
                    editorid={'class_intro'}
                    value={intro ? intro : ''}
                    onChange={(val) => {
                      // console.log('val', val)
                    }}
                  />
                </>
              )}
              {editstep == 3 && (
                <>
                  <div
                    className="frjustify_between splite_block"
                    style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}
                  >
                    <div className="font_D700_16">상품 키워드 입력</div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      직무<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 2 })}
                      ></div>
                      <this.renderKeyword data={jobtype} />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">기술스택</label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 3 })}
                      ></div>
                      <this.renderKeyword data={tech_stack} />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      난이도<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 4 })}
                      ></div>
                      <this.renderKeyword data={difficulty} />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      관심정보<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 5 })}
                      ></div>
                      <this.renderKeyword data={interest} />
                    </div>
                  </div>
                  <div className="card_row">
                    <label className="field_label">
                      고객유형<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 6 })}
                      ></div>
                      <this.renderKeyword data={customer_type} />
                    </div>
                  </div>

                  <div
                    className="frjustify_between splite_block w-100"
                    style={{ paddingTop: 20, paddingBottom: 10 }}
                  >
                    <div className="font_D700_16">
                      커리큘럼 <span className="danger-text">*</span>
                    </div>
                    <div className="fralign_center" style={{ gap: 10 }}>
                      <div
                        className="btn_link"
                        onClick={() => this.setState({ modalIdx: 7 })}
                      >
                        미리보기
                      </div>
                      <div
                        className="btn_link"
                        onClick={() => this.downloadTemplate()}
                      >
                        엑셀양식다운
                      </div>
                      <div
                        className="btn_normal"
                        onClick={this.openExcelSelect}
                      >
                        엑셀업로드
                      </div>
                    </div>
                    <input
                      type="file"
                      id="excel"
                      ref={(val) => (this.excelRef = val)}
                      style={{ display: 'none' }}
                      onChange={async (e) =>
                        e.target.files
                          ? this.readExcelFile(e.target.files[0])
                          : null
                      }
                      accept=".xlsx,.xls"
                    />
                  </div>
                  <div className="card_row">
                    <label className="field_label">총 교육시간</label>
                    <div className="card_row_val">
                      <input
                        type="number"
                        style={{ width: 100 }}
                        value={curriclum_time}
                        onChange={this.handleInputChange}
                        name="curriclum_time"
                        placeholder="50"
                      />
                      <span>시간</span>
                    </div>
                  </div>
                  <div className="fcjust_center w-100 gap-10">
                    {curriclumList.map((item, index) => (
                      <div className="query_block" key={index}>
                        <div className="fr_c_end w-100">
                          <div
                            className="close_btn"
                            onClick={() => this.removeCurriclum(index)}
                          />
                        </div>
                        <div className="fralign_center w-100 gap-20">
                          <input
                            type="text"
                            style={{ width: 800 }}
                            placeholder="대주제를 입력하세요.(필수입력)"
                            value={item.title}
                            onChange={(e) => {
                              item.title = e.target.value;
                              curriclumList[index] = item;
                              this.setState({
                                curriclum: JSON.stringify(curriclumList),
                              });
                            }}
                          />
                          <input
                            type="text"
                            style={{ flex: 1 }}
                            placeholder="시간 또는 주차 설명을 입력하세요.(선택입력)"
                            value={item.time}
                            onChange={(e) => {
                              item.time = e.target.value;
                              curriclumList[index] = item;
                              this.setState({
                                curriclum: JSON.stringify(curriclumList),
                              });
                            }}
                          />
                          <div style={{ width: 32 }}></div>
                        </div>
                        {item.data?.map((data, idx) => (
                          <div
                            className="fralign_center w-100 gap-20"
                            key={idx}
                          >
                            <div className="fralign_center">
                              <div className="sub_query_mark"></div>
                              <input
                                type="text"
                                style={{ width: 776 }}
                                placeholder="소주제를 입력하세요. (선택입력)"
                                value={data.title}
                                onChange={(e) => {
                                  data.title = e.target.value;
                                  item.data[idx] = data;
                                  curriclumList[index] = item;
                                  this.setState({
                                    curriclum: JSON.stringify(curriclumList),
                                  });
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === 'Enter' && e.shiftKey) {
                                    item.data.push({ title: '', time: '' });
                                    curriclumList[index] = item;
                                    this.setState({
                                      curriclum: JSON.stringify(curriclumList),
                                    });
                                  }
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              style={{ flex: 1 }}
                              placeholder="시간 또는 주차 설명을 입력하세요.(선택입력)"
                              value={data.time}
                              onChange={(e) => {
                                data.time = e.target.value;
                                item.data[idx] = data;
                                curriclumList[index] = item;
                                this.setState({
                                  curriclum: JSON.stringify(curriclumList),
                                });
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'Tab' &&
                                  item.data.length === idx + 1
                                ) {
                                  item.data.push({ title: '', time: '' });
                                  curriclumList[index] = item;
                                  this.setState({
                                    curriclum: JSON.stringify(curriclumList),
                                  });
                                }
                              }}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter' && e.shiftKey) {
                                  item.data.push({ title: '', time: '' });
                                  curriclumList[index] = item;
                                  this.setState({
                                    curriclum: JSON.stringify(curriclumList),
                                  });
                                }
                              }}
                            />

                            {item.data.length == idx + 1 ? (
                              <div
                                className="plus_btn"
                                onClick={() => {
                                  item.data.push({ title: '', time: '' });
                                  curriclumList[index] = item;
                                  this.setState({
                                    curriclum: JSON.stringify(curriclumList),
                                  });
                                }}
                              />
                            ) : (
                              <div
                                className="remove_btn"
                                onClick={() => {
                                  item.data.splice(idx, 1);
                                  curriclumList[index] = item;
                                  this.setState({
                                    curriclum: JSON.stringify(curriclumList),
                                  });
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                    <div
                      className="add_query"
                      onClick={() => {
                        curriclumList.push({
                          title: '',
                          time: '',
                          data: [
                            { title: '', time: '' },
                            { title: '', time: '' },
                            { title: '', time: '' },
                            { title: '', time: '' },
                            { title: '', time: '' },
                          ],
                        });
                        this.setState({
                          curriclum: JSON.stringify(curriclumList),
                        });
                      }}
                    >
                      <div className="plus_btn" />
                      대주제 필드 추가
                    </div>
                  </div>
                  <div className={`frjustify_between splite_block w-100 py-3 `}>
                    <div className="font_D700_16">수강후기</div>
                    <div className="fralign_center" style={{ gap: 20 }}>
                      <div
                        className="btn_normal"
                        onClick={() => this.setState({ modalIdx: 8 })}
                      >
                        리스트에서 선택
                      </div>
                      <div
                        className="btn_normal"
                        onClick={() => {
                          if (!review_input) {
                            this.setState({
                              review_input: true,
                              review_title: '',
                              review_link: '',
                            });
                          } else {
                            reviewList.push({
                              title: review_title,
                              link: review_link,
                              img: '',
                            });
                            this.setState({
                              review_input: false,
                              lesson_review: JSON.stringify(reviewList),
                            });
                          }
                        }}
                      >
                        {review_input ? '추가' : '직접 입력'}
                      </div>
                    </div>
                  </div>
                  {review_input && (
                    <div className={`card_row`}>
                      <div className="card_row_val">
                        <input
                          type="text"
                          className="w-100"
                          value={review_title}
                          onChange={({ target }) =>
                            this.setState({ review_title: target.value })
                          }
                          placeholder={'제목을 입력하세요.'}
                        />
                        <input
                          type="text"
                          className="w-100"
                          value={review_link}
                          onChange={({ target }) =>
                            this.setState({ review_link: target.value })
                          }
                          placeholder={'URL을 입력하세요.'}
                        />
                      </div>
                      <span>
                        <div
                          className="remove_btn"
                          onClick={() =>
                            this.setState({
                              review_input: false,
                              review_title: '',
                              review_link: '',
                            })
                          }
                        />
                      </span>
                    </div>
                  )}
                  {reviewList.map((item, index) => (
                    <div className={`card_row`} key={index}>
                      <div className="card_row_val">
                        <div>
                          <div className="mb-1">{item.title}</div>
                          <div className="gray-text fs-12">{item.link}</div>
                        </div>
                      </div>
                      <span>
                        <div
                          className="remove_btn"
                          onClick={() => {
                            reviewList.splice(index, 1);
                            this.setState({
                              lesson_review: JSON.stringify(reviewList),
                            });
                          }}
                        />
                      </span>
                    </div>
                  ))}
                  <div
                    className="frjustify_between splite_block"
                    style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}
                  >
                    <div className="font_D700_16">FAQ</div>
                  </div>
                  <div className="fcjust_center w-100 gap-10">
                    {faqList.map((item, index) => (
                      <div className="query_block" key={index}>
                        <div className="fr_c_end w-100">
                          <div
                            className="close_btn"
                            onClick={() => {
                              let tmpList = faqList;
                              tmpList.splice(index, 1);
                              this.setState({ faq: JSON.stringify(tmpList) });
                            }}
                          />
                        </div>
                        <div className="fralign_center w-100 gap-20">
                          <input
                            type="text"
                            className="flex-1"
                            placeholder="질문을 입력하세요."
                            value={item.question}
                            onChange={(e) => {
                              item.question = e.target.value;
                              faqList[index] = item;
                              this.setState({ faq: JSON.stringify(faqList) });
                            }}
                          />
                        </div>
                        <div className="fralign_start w-100">
                          <div className="sub_query_mark"></div>
                          <textarea
                            className="flex-1"
                            placeholder={'답변을 입력하세요.'}
                            value={item.answer}
                            onChange={(e) => {
                              item.answer = e.target.value;
                              faqList[index] = item;
                              this.setState({ faq: JSON.stringify(faqList) });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <div
                      className="add_query"
                      onClick={() => {
                        faqList.push({ question: '', answer: '' });
                        this.setState({ faq: JSON.stringify(faqList) });
                      }}
                    >
                      <div className="plus_btn" />
                      필드 추가
                    </div>
                  </div>
                </>
              )}

              {editstep === 4 && (
                <div className="waitpage">
                  <div className="eduprodname">[{title}]</div>
                  <div className="page_msg">교육 정보가 등록되었습니다.</div>
                  <div className="page_des">
                    관리자의 승인 이후 PickIT에 페이지에 게시됩니다.
                    <br />
                    게시물 승인은 약 1~2일 소요될 수 있습니다.
                  </div>
                  <div
                    className="btn_normal"
                    onClick={() => this.gotoPage('/education/leasson')}
                  >
                    교육 상품 리스트로 이동
                  </div>
                </div>
              )}

              {set_status == 5 && Boolean(productId) && (
                <div className="fr_c_end w-100 py-3">
                  <div
                    className="btn_remove"
                    style={{ width: 120 }}
                    onClick={() =>
                      this.setState({
                        confirmTitle: '삭제하기',
                        confirmTxt:
                          '임시 저장된 교육 등록 정보를 모두 삭제하시겠습니까? \n삭제한 정보는 복구할 수 없습니다.',
                        modalIdx: 10,
                      })
                    }
                  >
                    상품 삭제
                  </div>
                </div>
              )}

              {/* {modalIdx == 1 && <EduKeyword   closeModal={() => this.setState({modalIdx: 0})} selectDatas={({id, user_name}) => this.setState({partner_id: id, user_name})} partnerId={partner_id} user_name={user_name} />} */}
              {modalIdx == 2 && (
                <JobKeyword
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(jobtype) => this.setState({ jobtype })}
                  category={jobtype}
                />
              )}
              {modalIdx == 3 && (
                <TechKeyword
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(tech_stack) => this.setState({ tech_stack })}
                  category={tech_stack}
                />
              )}
              {modalIdx == 4 && (
                <KeywordsModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(difficulty) => this.setState({ difficulty })}
                  category={difficulty}
                  type={'D'}
                />
              )}
              {modalIdx == 5 && (
                <KeywordsModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(interest) => this.setState({ interest })}
                  category={interest}
                  type={'I'}
                />
              )}
              {modalIdx == 6 && (
                <KeywordsModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(customer_type) =>
                    this.setState({ customer_type })
                  }
                  category={customer_type}
                  type={'C'}
                />
              )}
              {modalIdx == 7 && (
                <QueryPreView
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  curriclumList={curriclumList}
                />
              )}
              {modalIdx == 8 && (
                <LessonReviewModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  title={title}
                  selectDatas={(selectedList) =>
                    this.setState({
                      lesson_review: JSON.stringify(selectedList),
                    })
                  }
                  selectedList={reviewList}
                />
              )}
              {modalIdx == 9 && (
                <EditAnswer
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  title={'반려 사유 입력'}
                  text={return_txt}
                  selectDatas={this.returnProduct}
                />
              )}

              {modalIdx >= 10 && (
                <ConformModal
                  closeModal={() =>
                    this.setState({ confirmTxt: '', modalIdx: 0 })
                  }
                  onConfirm={this.onConfirm}
                  title={confirmTitle}
                >
                  {confirmTxt}
                </ConformModal>
              )}
            </div>
          </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(NewLeasson);
