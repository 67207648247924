import React from 'react';

import './mypage.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, getCurrentTime, formatTime } from '../../libs/utils';

class AlermList extends React.Component {
  constructor() {
    super();
    this.state = {
      totalCnt:     0,
      currentPage:  1,
      dataList:     []
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();
    // 
    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const params = { currentPage }
    requestPost('getAlarmList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }
  readAll = () => {
    requestPost('udpateAlarmRead', {}, (res) => {
        updateMyAlarm();
        this.getData();
    });
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }
  openContentsPage = (data) => {
    const {id, type, target_id} = data;

    requestPost('udpateAlarmRead', {id}, (res) => {
        updateMyAlarm()
    });
    // 
    if(type == 1) { // 관리자가 승인 대기 중인 교육 상품을 노출로 변경했을 때
        this.gotoPage(`/education/leasson/detail?id=${target_id}`);
    }
    else if(type == 2) { // 관리자가 교육 정보 수정을 승인했을 때
        this.gotoPage(`/education/leasson/detail?id=${target_id}`);
    }
    else if(type == 3) { // 관리자가 교육 정보 수정을 반려했을 때
        this.gotoPage(`/education/leasson/detail?id=${target_id}`);
    }
    else if(type == 4) { // 상품 문의가 신규 접수되었을 때
        this.gotoPage(`/education/queryprod/detail?id=${target_id}`);
    }
}

  render() {
    const { totalCnt, currentPage, dataList } = this.state;
      
    return (
    <Leftmenu>
      <div className='page_contents alermlist'>
        <div className='card'>
          <div className='card_head'>
            <div className='card_head_title'>알림 전체보기</div>
            <div className='card_head_tool'>
              <div className='btn_normal' onClick={this.readAll}>모두 읽은 상태로 변경</div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>알림 내용</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dataList.length > 0 ?
                dataList.map((item, index) =>
                <tr className='pointer' key={index} onClick={() => this.openContentsPage(item)}>
                  <td className={`${item.read_flag == 0 ? 'on' : ''}`}>{item.title}</td>
                  <td className={`${item.read_flag == 0 ? 'on' : ''}`}>{formatDate(item.reg_time).indexOf(getCurrentTime(false)) >= 0 ? formatTime(item.reg_time) : formatDate(item.reg_time)}</td>
                </tr>
                )
                :
                <tr><td colSpan={2}>데이터가 없습니다.</td></tr>
              }
            </tbody>
          </table>
          <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
        </div>
      </div>
    </Leftmenu>
    );
  }
}

export default withRouter(AlermList);